$(function(){
	new WOW().init();
	/* sliders */
	$('.slider').slick({
		autoplay: true,
		autoplaySpeed: 3200,
		speed: 1200
	});

	$('.gallery').slick({
		autoplay: true,
		autoplaySpeed: 3000,
		slidesToShow: 6,
		speed: 800,
		responsive: [
		    {
		      breakpoint: 991,
		      settings: {
		        slidesToShow: 5
		      }
		    },
		    {
		      breakpoint: 768,
		      settings: {
		        slidesToShow: 4
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 2
		      }
		    },
		    {
		      breakpoint: 380,
		      settings: {
		        slidesToShow: 1
		      }
		    }
		]
	});

	$('.page--slider').slick({
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: false,
		dots: true
	});

	$('.slider--for').slick({
	 	slidesToShow: 1,
	  	slidesToScroll: 1,
	  	arrows: false,
	  	fade: true,
	  	asNavFor: '.slider--nav'
	});
	$('.slider--nav').slick({
		autoplay: true,
	  	slidesToShow: 3,
	  	slidesToScroll: 1,
	  	focusOnSelect: true,
	  	asNavFor: '.slider--for'
	});

	$('.good--similar__slider').slick({
		autoplay: true,
		slidesToShow: 4,
	  	slidesToScroll: 1, 
	  	responsive: [
		    {
		      breakpoint: 768,
		      settings: {
		        slidesToShow: 3
		      }
		    },
		    {
		      breakpoint: 680,
		      settings: {
		        slidesToShow: 2
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 1
		      }
		    }
		]
	});
	/* sliders */

	$('.page--cat__col__btn').on('click', function(){
		$(this).next().slideToggle('fast');
		$(this).toggleClass('open');
	});

	$('.map__block .close').on('click', function(){
		$(this).parent().toggleClass('hide');
		$(this).toggleClass('active');
		return false;
	});

	$('.btn--up').bind('click.smoothscroll',function (e) {
		e.preventDefault();
		var target = this.hash,
		$target = $(target);

		$('html, body').stop().animate({
			'scrollTop': $target.offset().top
			}, 1000, 'swing', function () {
			window.location.hash = target;
		});
	});

	$('.nav--btn').on('click', function(){
		if($('.nav > ul').is(':visible')) {
			$('.nav > ul').slideUp(); 
		} else {
			$('.nav > ul').slideDown(); 
		}
		$(this).toggleClass('open');
		return false;
	})

	// $('.submenu > a').on('click', function(){
	//   	var width = $(window).width();
	//     if (width < 1170) {
	//    		$(this).next().slideToggle(250).parent().toggleClass('active');
	// 		$('.submenu > a').not(this).siblings('.submenu__block:visible').slideUp().parent().removeClass('active');	
	// 		return false;
	//     } 
	// });

	$('.submenu > a').on('mouseover', function(){
		var width = $(window).width();
	    if (width > 1170) {
			$(this).next().fadeToggle(250).parent().toggleClass('active');
			$('.submenu > a').not(this).siblings('submenu__block:visible').fadeOut().parent().removeClass('active');	
			return false;
	    }
	});
	$('.submenu').on('mouseleave', function(){
		$(this).children('.submenu__block').fadeOut(250);
		$(this).removeClass('active');
	});
    $(window).scroll(function(){
        var bo = $(this).scrollTop();
        var a = $(".btn--up").css('opacity')
		if ( bo >= 200 && a == 0) {$(".btn--up").stop().animate({'opacity':'1'},400)};
        if ( bo < 200 && a == 1) {$(".btn--up").stop().animate({'opacity':'0'},400)};
    });

    $('.close').on('click', function(){
    	return false;
    });

    var overlay = $('.overlay'); // пoдлoжкa, дoлжнa быть oднa нa стрaнице
    var open_modal = $('.open--modal'); // все ссылки, кoтoрые будут oткрывaть oкнa
    var close = $('.modal .close, .overlay'); // все, чтo зaкрывaет мoдaльнoе oкнo, т.е. крестик и oверлэй-пoдлoжкa
    var modal = $('.modal'); // все скрытые мoдaльные oкнa
    var modalBlock = $('.modal__block')
    
    open_modal.click( function(event){ // лoвим клик пo ссылке с клaссoм open_modal
         event.preventDefault(); // вырубaем стaндaртнoе пoведение
         var div = $(this).attr('href'); // вoзьмем стрoку с селектoрoм у кликнутoй ссылки
         overlay.fadeIn(400, //пoкaзывaем oверлэй
             function(){ // пoсле oкoнчaния пoкaзывaния oверлэя
                $(div).fadeIn();
         });

     });

     close.click( function(){ // лoвим клик пo крестику или oверлэю
            modal.fadeOut(400);
    });


    $('.phones--btn').on('click', function(){
		if($('header .phones').is(':visible')) {
			$('header .phones').slideUp().removeClass('open');
		} else {
			$('header .phones').slideDown().addClass('open');
		}
		$(document).bind("touchstart",function(e) {
		    if (!$(e.target).closest("header .phones.open").length) {
		        $('header .phones').slideUp().removeClass('open');
		    }
		    e.stopPropagation();
		});
		return false;
	});
	$('.location--btn').on('click', function(){
		if($('header .location').is(':visible')) {
			$('header .location').slideUp().removeClass('open');
		} else {
			$('header .location').slideDown().addClass('open');
		}
		$(document).bind("touchstart",function(e) {
		    if (!$(e.target).closest("header .location.open").length) {
		        $('header .location').slideUp().removeClass('open');
		    }
		    e.stopPropagation();
		});
		return false;
	});

	$('.search--btn').on('click', function(){
		$('.search').fadeToggle();
		return false;
	});

	$('.input--phone').mask('+7 (999) 999-9999');
});


	

function windowSize(){
    if ($(window).width() <= '1251'){
    	$('.breadcrumbs').prependTo('.page');
        
    } else {
        
        $('.breadcrumbs').appendTo('header .container');
    }
}
$(window).resize(windowSize);
$(window).on('load resize',windowSize);